<template>
  <div class="my-application">
    <h2>상영 신청 내역</h2>
  </div>
</template>

<script>
export default {
  title: '상영 신청 내역',
};
</script>

<style lang="scss" scoped>

h2 {
  font-size: 20px;
  font-weight: bold;
}
</style>

<style scoped></style>

<style></style>
